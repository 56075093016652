import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axiosInstance from '@/axios-instance';
import AMap from 'vue-amap';

document.addEventListener('DOMContentLoaded', function () {
  if (window.initMap) {
    window.initMap();
  }
});

Vue.use(AMap);

Vue.use(ElementUI);
Vue.config.productionTip = false
// 注册 Axios 实例到 Vue 原型上
Vue.prototype.$axios = axiosInstance;

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    document.title = to.meta.title || '翔云模拟飞行平台';
    next();
  }
});

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});

setTimeout(() => {
  localStorage.removeItem('user-token');
  // 如果需要，可以在这里重定向到登录页面
},  60 * 60 * 1000); // 10000毫秒 = 10秒