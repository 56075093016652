import Vue from 'vue'
import VueRouter from 'vue-router'
import Personal from '@/views/New/PersonalView.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/New/XiangYunInfoView.vue'),
    meta: { title: 'CXY|翔云模拟飞行平台官网-首页  ClubXiangYunFlightSimulation' },

  },
  {
    path: '/Register',
    name: '注册',
    component: () => import('../views/New/RegisterView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-呼号注册' ,
      description:'翔云模拟飞行平台呼号注册，联飞资格注册  ClubXiangYunFlightSimulation'}
  },
  {
    path: '/FlyOnline',
    name: '联飞中心',
    component: () => import('../views/New/FlyOnlineView.vue'),
    meta: { title: 'CXY 联飞中心-翔云模拟飞行服务系统数据中心 ClubXiangYunFlightSimulation' }
  },
  {
    path: '/Login',
    name: '登录',
    component: () => import('../views/New/LoginView.vue'),
    meta: { title: '翔云模拟飞行-登录-CXY飞行服务系统|ClubXiangYunLogin' },
  },
  {
    path: '/Vip/:userId',
    name: 'Vip开通',
    component: () => import('../views/New/VipView.vue'),
    meta: { title: '翔云模拟飞行-VIP开通-ClubXiangYunVIP' }
  },
  {
    path: '/Connection/exam/:userId',
    name: '连线考试',
    component: () => import('../views/New/ConnectionExamView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-考试系统  ClubXiangYunFlightSimulation' }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/New/404View.vue'),
    meta: { title: '404' }
  },
  {
    path: '/MyLicence/user/:userId',
    name: '我的执照',
    component: () => import('../views/New/MyLicenceView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-飞行执照 ClubXiangYunFlight' }
  },
  {
    path: '/ATC/recruit',
    name: 'ATC招募',
    component: () => import('../views/New/ATC/recruitView.vue'),
    meta: { title: '翔云模拟飞行-ATC招募通知 ClubXiangYunATC' ,
      description:'翔云模拟飞行平台管制员招募通知，通知文件'}
  },
  {
    path: '/Personal/user/:userId',
    name: '个人主页',
    component: Personal ,
    meta: { requiresAuth: true ,title: '翔云模拟飞行-飞行员主页 ClubXiangYun',
      description:'翔云模拟飞行平台飞行员主页，飞行员个人资料编辑，查看个人资料'}
  },
  {
    path: '/ATCLogin',
    name: '管制员登录',
    component: () => import('../views/New/ATC/ATCLoginView.vue'),
    meta: { title: '翔云模拟飞行-ATC管制员面板-登录 ClubXiangYunFlightSimulation',
      description:'登录搜寻更多有趣的事，联飞，注册账户，成为ATC  ClubXiangYunFlightSimulation' }
  },
  {
    path: '/ConsoleATC/:userId',
    name: '管制员控制台',
    component: () => import('../views/New/ATC/ConsoleATCView.vue'),
  meta: { title: '翔云模拟飞行ATC管制员面板-控制台' ,
    description:'翔云模拟飞行平台ATC面板，ATC专用面板，ATC管制员面板'}
  },
  {
    path: '/events',
    name: '连飞活动',
    component: () => import('../views/New/eventsView.vue'),
    meta: { title: '翔云模拟飞行活动列表-查看发布活动 XiangYun' ,
      description:'翔云模拟飞行平台连飞活动列表，连飞日期，活动数据'}
  },
  {
    path: '/Route',
    name: '航路查询',
    component: () => import('../views/New/RouteView.vue'),
    meta: { title: 'CXY 全球航路查询系统-飞行计划 XiangYun' ,
      description:'翔云模拟飞行全球航路查询系统 飞行计划，模拟飞行航路，航空航路，模拟飞行航路查询'}
  },
  {
    path: '/Weather',
    name: '气象查询',
    component: () => import('../views/New/WeatherView.vue'),
    meta: { title: '翔云模拟飞行-全球机场气象查询机场气象报文报文查询 XiangYun' ,
      description:'CXY 全球气象报文查询，飞行气象，航空气象，真实气象查询'}
  },
  {
    path: '/VA',
    name: 'VA虚拟航司',
    component: () => import('../views/New/VA/VAView.vue'),
    meta: { title: 'VA虚拟航司-翔云模拟飞行 虚拟航空公司是模拟现实世界航空公司 XiangYun 飞行任务管理，在线飞行，飞行任务管理，社区互动，训练和支持' ,
      description:'CXY 飞行任务管理：成员可以接受并完成飞行任务，这些任务可以是按照预定航线进行的，也可以是特殊任务 统计跟踪：系统会跟踪飞行小时数、起降次数等统计数据 ' +
          '在线飞行：成员可以通过网络与其他飞行员一起飞行，使用如CXY这样的虚拟空中交通管制网络进行实时通信。' +
          '社区互动：论坛、聊天室和其他社交工具用于成员之间的交流和协作。' +
          '训练和支持'}
  },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // 只有当目标路由是 /personal 时才进行 token 检查
  if (to.path === '/personal' && to.path==='/MyLicenceView.vue') {
    const token = localStorage.getItem('user-token');

    if (!token) {
      // 如果没有 token，则重定向到登录页面
      next('/login');
    } else {
      // 如果有 token，则允许访问 personal 页面
      next();
    }
  } else {
    // 如果目标不是 /personal，则直接放行
    next();
  }
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '翔云模拟飞行平台';
  next();
});
export default router
